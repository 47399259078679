'use client'

import { Card, Divider, Spacer, Text, Button, Cell, Icon } from '@vinted/web-ui'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { InfoCircle24, Money24 } from '@vinted/monochrome-icons'

import { getLocale } from 'state/intl/selectors'
import { formatCurrencyAmount } from 'libs/utils/formatString'
import { clickEvent, viewEvent } from 'libs/common/event-tracker/events'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { Screen } from 'constants/tracking/screens'

import useTranslate from 'hooks/useTranslate'
import useTracking from 'hooks/useTracking'

import ContentLoader from 'components/ContentLoader'
import { FormattedMessage } from 'components/@support'

import useAbTest from 'hooks/useAbTest'
import { ViewableElement } from 'constants/tracking/viewable-elements'
import { SellerInsightsType } from 'constants/seller-insights'

import useGetPhotoRecommendations from './hooks/useGetPhotoRecommendations'
import SellerInsightsValuePropositionModal from './SellerInsightsValuePropositionModal/SellerInsightsValuePropositionModal'
import SellerInsightsPhotoRecommendations from './SellerInsightsPhotoRecommendations/SellerInsightsPhotoRecommendations'

type Props = {
  isSellerInsightsLoading: boolean
  sellerInsights: SellerInsightsType
}

const SellerInsights = ({ sellerInsights, isSellerInsightsLoading }: Props) => {
  const { photoRecommendations, isPhotoRecommendationsLoading } = useGetPhotoRecommendations()

  const { track } = useTracking()
  const [openModal, setOpenModal] = useState(false)

  const locale = useSelector(getLocale)
  const translate = useTranslate('profile.seller_insights')

  const activeItemsPrice = sellerInsights?.activeItemsStats.total
  const soldItemsPrice = sellerInsights?.soldItemsStats.total
  const recentItemsPrice = sellerInsights?.soldItemsStats.period.total
  const recentItemsPeriod = sellerInsights?.soldItemsStats.period.duration

  const sellerInsightsV2AbTest = useAbTest({
    abTestName: 'seller_insights_add_photos_web',
  })
  const isSellerInsightsV2Enabled =
    sellerInsightsV2AbTest && sellerInsightsV2AbTest.variant !== 'off'

  useEffect(() => {
    track(
      viewEvent({
        target: ViewableElement.SellerInsightsBlocks,
        screen: Screen.CurrentUserProfile,
      }),
    )
  }, [track])

  const handleOpenModal = () => {
    track(
      clickEvent({
        target: isSellerInsightsV2Enabled
          ? ClickableElement.SellerInsightsInfo
          : ClickableElement.SellerInsightsSeeMore,
        screen: Screen.CurrentUserProfile,
      }),
    )
    setOpenModal(true)
  }

  const handleCloseModal = () => setOpenModal(false)

  const renderHeader = () =>
    isSellerInsightsV2Enabled ? (
      <Cell
        prefix={<Icon name={Money24} color={Icon.Color.GreyscaleLevel3} />}
        title={translate('title_secondary')}
        suffix={
          <Button
            inline
            iconName={InfoCircle24}
            size={Button.Size.Small}
            styling={Button.Styling.Flat}
            iconColor={Icon.Color.GreyscaleLevel3}
            onClick={handleOpenModal}
            testId="seller-insights-info"
            aria={{ 'aria-label': translate('link') }}
          />
        }
        styling={Cell.Styling.Tight}
        testId="seller-insights-header-v2"
      />
    ) : (
      <>
        <Text text={translate('title')} type={Text.Type.Caption} as="h2" />
        <Button
          inline
          text={translate('link')}
          size={Button.Size.Small}
          styling={Button.Styling.Flat}
          testId="seller-insights-see-more"
          onClick={handleOpenModal}
        />
      </>
    )

  if (isSellerInsightsLoading || isPhotoRecommendationsLoading)
    return <ContentLoader testId="seller-insights-loader" />

  if (!sellerInsights?.isVisible) return null

  return (
    <section>
      <Spacer size={Spacer.Size.Medium} />
      <div className="u-flexbox u-flex-direction-row u-align-items-center u-justify-content-between">
        {renderHeader()}
      </div>
      <Spacer size={isSellerInsightsV2Enabled ? Spacer.Size.Medium : Spacer.Size.Small} />
      <Card>
        <Cell testId="seller-insights-blocks" styling={Cell.Styling.Tight}>
          <div className="u-flexbox u-flex-direction-row u-padding-medium">
            <section className="u-flexbox u-flex-direction-column u-align-items-center u-half-width">
              <Text
                text={translate('blocks.wardrobe_value.title')}
                type={Text.Type.Caption}
                as="h3"
              />
              <Text
                text={activeItemsPrice ? formatCurrencyAmount(activeItemsPrice, locale) : ''}
                type={Text.Type.Title}
              />
              <Text type={Text.Type.Caption}>
                <FormattedMessage
                  pluralize
                  count={sellerInsights?.activeItemsStats.count}
                  id="profile.seller_insights.blocks.wardrobe_value.subtitle"
                  values={{ wardrobe_items_count: sellerInsights?.activeItemsStats.count }}
                />
              </Text>
            </section>
            <Divider orientation={Divider.Orientation.Vertical} />
            <section className="u-flexbox u-flex-direction-column u-align-items-center u-half-width">
              <Text text={translate('blocks.earnings.title')} type={Text.Type.Caption} as="h3" />
              <Text
                text={soldItemsPrice ? formatCurrencyAmount(soldItemsPrice, locale) : ''}
                type={Text.Type.Title}
              />
              <Text type={Text.Type.Caption} theme="success">
                {translate('blocks.earnings.subtitle', {
                  total_earned: recentItemsPrice
                    ? formatCurrencyAmount(recentItemsPrice, locale)
                    : '',
                })}
              </Text>
            </section>
          </div>
        </Cell>
      </Card>
      {isSellerInsightsV2Enabled && photoRecommendations && (
        <SellerInsightsPhotoRecommendations {...photoRecommendations} />
      )}
      <Spacer size={Spacer.Size.Medium} />
      <SellerInsightsValuePropositionModal
        show={openModal}
        onCloseOrConfirm={handleCloseModal}
        period={recentItemsPeriod}
      />
    </section>
  )
}

export default SellerInsights
