export enum ReplyTimeThreshold {
  VerySlow = 0,
  Slow = 24,
  Moderate = 12,
  Fast = 9,
  VeryFast = 6,
}

export enum QuickReplierProgress {
  VerySlow = 0,
  Slow = 0.25,
  Moderate = 0.5,
  Fast = 0.75,
  VeryFast = 1,
}
