'use client'

import { X24 } from '@vinted/monochrome-icons'
import { Button, Cell, Divider, Dialog, Text, Spacer, Icon } from '@vinted/web-ui'
import { QuickRepliesBadge72, StarListerBadge72 } from '@vinted/multichrome-icons'

import useTracking from 'hooks/useTracking'
import useTranslate from 'hooks/useTranslate'

import { ITEM_UPLOAD_URL, SELLING_HELP_URL, TERMS_URL } from 'constants/routes'
import { INBOX_URL } from 'constants/routes/inbox'
import { Screen } from 'constants/tracking/screens'
import { clickEvent } from 'libs/common/event-tracker/events'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import ScrollableArea from 'components/ScrollableArea'
import { UserBadgeDto } from 'types/dtos'

type Props = {
  selectedBadge: UserBadgeDto | null
  onClose: () => void
}

const BadgeInfoModal = ({ selectedBadge, onClose }: Props) => {
  const translate = useTranslate('profile.badges')
  const { track } = useTracking()

  const handleOnClose = () => {
    track(clickEvent({ screen: Screen.ListerBadgeInfo, target: ClickableElement.CloseBadgeInfo }))
    onClose()
  }

  const handleClick = () => {
    // update tracking when requirements are updated
    track(clickEvent({ screen: Screen.ListerBadgeInfo, target: ClickableElement.UploadItem }))
  }

  if (!selectedBadge) return null

  const getHeaderText = () => {
    switch (selectedBadge.type) {
      case 'ACTIVE_LISTER':
        return translate('active_lister.modal.title')
      case 'FAST_REPLIER':
        return translate('quick_replier.modal.title')
      default:
        return ''
    }
  }

  const getBodyText = () => {
    switch (selectedBadge.type) {
      case 'ACTIVE_LISTER':
        return translate('active_lister.modal.body', {
          items_count: selectedBadge?.progress.goal,
          'catalog-rules-link': chunks => (
            <a href={SELLING_HELP_URL} target="_blank" rel="noopener noreferrer">
              {chunks}
            </a>
          ),
          'terms-and-conditions-link': chunks => (
            <a href={TERMS_URL} target="_blank" rel="noopener noreferrer">
              {chunks}
            </a>
          ),
        })
      case 'FAST_REPLIER':
        return translate('quick_replier.modal.body')
      default:
        return ''
    }
  }

  const getIconName = () => {
    switch (selectedBadge.type) {
      case 'ACTIVE_LISTER':
        return StarListerBadge72
      case 'FAST_REPLIER':
        return QuickRepliesBadge72
      default:
        return StarListerBadge72
    }
  }

  const getActionUrl = () => {
    switch (selectedBadge.type) {
      case 'ACTIVE_LISTER':
        return ITEM_UPLOAD_URL
      case 'FAST_REPLIER':
        return INBOX_URL
      default:
        return undefined
    }
  }

  const getActionText = () => {
    switch (selectedBadge.type) {
      case 'ACTIVE_LISTER':
        return translate('active_lister.modal.actions.upload_item_now')
      case 'FAST_REPLIER':
        return translate('quick_replier.modal.actions.check_inbox')
      default:
        return ''
    }
  }

  const renderHeader = () => {
    return (
      <>
        <Cell
          styling={Cell.Styling.Tight}
          prefix={<Spacer orientation={Spacer.Orientation.Horizontal} size={Spacer.Size.X3Large} />}
          suffix={
            <Button
              iconName={X24}
              styling={Button.Styling.Flat}
              onClick={handleOnClose}
              testId="badge-info-modal-close"
            />
          }
        >
          <Spacer size={Spacer.Size.Large} />
          <div className="u-text-center">
            <Text type={Text.Type.Title} alignment={Text.Alignment.Center} text={getHeaderText()} />
          </div>
          <Spacer size={Spacer.Size.Large} />
        </Cell>
        <Divider />
      </>
    )
  }

  const renderBody = () => {
    return (
      <>
        <div className="u-flexbox u-justify-content-center">
          <Icon name={getIconName()} />
        </div>
        <Spacer size={Spacer.Size.Medium} />
        <Text text={getBodyText()} html />
      </>
    )
  }

  const renderActions = () => {
    return (
      <>
        <Spacer size={Spacer.Size.X2Large} />
        <Button
          text={getActionText()}
          styling={Button.Styling.Filled}
          url={getActionUrl()}
          onClick={handleClick}
          testId="badge-info-modal-upload-item"
        />
      </>
    )
  }

  return (
    <Dialog
      show={!!selectedBadge}
      closeOnOverlay
      defaultCallback={handleOnClose}
      hasScrollableContent
    >
      <div>
        {renderHeader()}
        <ScrollableArea>
          <Cell>
            {renderBody()}
            {renderActions()}
          </Cell>
        </ScrollableArea>
      </div>
    </Dialog>
  )
}

export default BadgeInfoModal
