'use client'

import { InfoCircle24 } from '@vinted/monochrome-icons'
import { Card, Cell, Spacer, Image, Text, Icon } from '@vinted/web-ui'

type Props = {
  titleText: string
  bodyText: string
  iconSrc: string | null
  onClick: () => void
}

const BadgeBanner = ({ titleText, bodyText, iconSrc, onClick }: Props) => {
  return (
    <div className="u-fill-width u-fill-child-component">
      <Card>
        <div className="u-overflow-hidden u-fill-height u-fill-child-component">
          <Cell type={Cell.Type.Navigating} onClick={onClick} testId="badge-banner">
            <div className="u-flexbox u-justify-content-between">
              <div className="u-flexbox">
                <div className="u-flexbox u-align-self-center">
                  <Image
                    src={iconSrc}
                    alt=""
                    size={Image.Size.Large}
                    scaling={Image.Scaling.Contain}
                  />
                </div>
                <Spacer size={Spacer.Size.Regular} orientation={Spacer.Orientation.Vertical} />
                <div className="u-align-self-center">
                  <Text text={titleText} type={Text.Type.Title} />
                  <br />
                  <Text text={bodyText} />
                </div>
              </div>
              <Spacer size={Spacer.Size.Regular} orientation={Spacer.Orientation.Vertical} />
              <div className="u-align-self-center">
                <Icon name={InfoCircle24} color={Icon.Color.GreyscaleLevel3} />
              </div>
            </div>
          </Cell>
        </div>
      </Card>
    </div>
  )
}

export default BadgeBanner
