'use client'

import { useEffect, useState } from 'react'
import { Spacer } from '@vinted/web-ui'
import classNames from 'classnames'

import useTranslate from 'hooks/useTranslate'
import useFetch from 'hooks/useFetch'
import useTracking from 'hooks/useTracking'
import useAsset from 'hooks/useAsset'
import useAbTest from 'hooks/useAbTest'
import useBreakpoint from 'hooks/useBreakpoint'
import useSession from 'hooks/useSession'
import useFeatureSwitch from 'hooks/useFeatureSwitch'

import { getSellerBadges } from 'data/api'
import { transformSellerBadgesResponse } from 'data/api/transformers/response'
import { Screen } from 'constants/tracking/screens'
import { clickEvent } from 'libs/common/event-tracker/events'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { UserBadgeDto } from 'types/dtos'

import SeparatedList from 'components/SeparatedList'

import BadgeInfoModal from './BadgeInfoModal'
import BadgeBanner from './BadgeBanner'
import { ReplyTimeThreshold } from './constants'

const ProfileBadges = () => {
  const translate = useTranslate('profile.badges')
  const { track } = useTracking()
  const breakpoints = useBreakpoint()
  const asset = useAsset('/assets/user-badges')
  const fastReplierAbTest = useAbTest({
    abTestName: 'fast_replier_badge',
    shouldTrackExpose: true,
  })
  const isFastReplierBadgeEnabled = useFeatureSwitch('fast_replier_badge_seller_side_web')

  const [selectedBadge, setSelectedBadge] = useState<UserBadgeDto | null>(null)

  const { user } = useSession()

  const { transformedData: badges, fetch: fetchBadges } = useFetch(
    getSellerBadges,
    transformSellerBadgesResponse,
  )

  const showProfileBadges = user && !user.business

  const showMultipleBadges =
    isFastReplierBadgeEnabled && !!fastReplierAbTest && fastReplierAbTest.variant !== 'off'

  const classes = classNames({ 'u-flexbox': breakpoints.tabletsUp })

  useEffect(() => {
    if (!showProfileBadges) return

    fetchBadges()
  }, [fetchBadges, showProfileBadges])

  const handleOnClick = (badge: UserBadgeDto) => () => {
    track(
      clickEvent({
        screen: Screen.CurrentUserProfile,
        target: ClickableElement.ListerBadge,
        targetDetails: JSON.stringify(badge.progress),
      }),
    )
    setSelectedBadge(badge)
  }

  const getTitleTranslation = (badge: UserBadgeDto) => {
    const { progress } = badge

    switch (badge.type) {
      case 'ACTIVE_LISTER': {
        if (progress.current === 0) return translate('active_lister.title')
        if (progress.current < progress.goal) return translate('active_lister.title_progress')

        return translate('active_lister.title_success')
      }
      case 'FAST_REPLIER': {
        if (progress.current <= progress.goal) return translate('quick_replier.title_success')
        if (progress.current > progress.goal && progress.current <= ReplyTimeThreshold.Slow)
          return translate('quick_replier.title_progress', { hours: progress.current })

        return translate('quick_replier.title')
      }
      default:
        return ''
    }
  }

  const getBodyTranslation = (badge: UserBadgeDto) => {
    const { progress } = badge

    switch (badge.type) {
      case 'ACTIVE_LISTER': {
        const itemsRemaining = progress.goal - progress.current

        if (progress.current === 0)
          return translate('active_lister.body', { items_count: progress.goal })
        if (progress.current < progress.goal)
          return translate(
            'active_lister.body_progress',
            { items_count: itemsRemaining },
            { count: itemsRemaining },
          )

        return translate('active_lister.body_success')
      }
      case 'FAST_REPLIER': {
        if (progress.current <= progress.goal) return translate('quick_replier.body_success')
        if (progress.current > progress.goal && progress.current <= ReplyTimeThreshold.Slow)
          return translate('quick_replier.body_progress')

        return translate('quick_replier.body')
      }
      default:
        return ''
    }
  }

  const getProgressIcon = (badge: UserBadgeDto) => {
    switch (badge.type) {
      case 'ACTIVE_LISTER': {
        const { progress } = badge

        if (progress.current === 0) return asset('lister-badge-empty.svg')
        if (progress.current === progress.goal) return asset('lister-badge-final.svg')

        return asset(`lister-badge-${progress.goal}-${progress.current}.svg`)
      }
      case 'FAST_REPLIER':
        // work in progress
        return asset('lister-badge-final.svg')
      default:
        return null
    }
  }

  function renderSeparator() {
    return breakpoints.phones ? (
      <Spacer orientation={Spacer.Orientation.Horizontal} size={Spacer.Size.Medium} />
    ) : (
      <Spacer orientation={Spacer.Orientation.Vertical} size={Spacer.Size.Large} />
    )
  }

  const renderBadge = (badge: UserBadgeDto | undefined) => {
    if (!badge) return null

    return (
      <BadgeBanner
        key={badge.type}
        titleText={getTitleTranslation(badge)}
        bodyText={getBodyTranslation(badge)}
        iconSrc={getProgressIcon(badge)}
        onClick={handleOnClick(badge)}
      />
    )
  }

  const renderProfileBadges = () => {
    if (!badges?.length) return null

    return (
      <div className={classes}>
        <SeparatedList separator={renderSeparator()}>{badges.map(renderBadge)}</SeparatedList>
      </div>
    )
  }

  if (!badges?.length) return null

  const getActiveListerBadge = () => badges.find(badge => badge.type === 'ACTIVE_LISTER')

  return (
    <>
      <Spacer size={Spacer.Size.Medium} />
      {showMultipleBadges ? renderProfileBadges() : renderBadge(getActiveListerBadge())}
      <BadgeInfoModal selectedBadge={selectedBadge} onClose={() => setSelectedBadge(null)} />
    </>
  )
}

export default ProfileBadges
